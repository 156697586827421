.datetime-picker {
  z-index: 200;
}
.tool {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px auto 5px auto;
  width: 95%;
}
.tool .rbc-toolbar-label {
  font-size: 24px;
  font-weight: bold;
}
.rbc-btn-group button {
  border-radius: 7px !important;
}

.rbc-header {
  background-color: #f7f7f7;
  padding: 20px !important;
  border-left: none;
}
.rbc-header + .rbc-header {
  border-left: none;
}
.rbc-month-view {
  width: 95%;
  margin: 20px auto;
  background-color: #fdfdfd;
  border: none;
  border-radius: 7px;
}
.rbc-header span {
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
}
.rbc-date-cell {
  padding-top: 10px;
  padding-right: 10px;
}
.rbc-event {
  height: 29px;
  margin-top: 9px;
  padding: 5px;
}
.rbc-now button {
  background-color: #0d46bc;
  height: 30px;
  width: 30px;
  display: inline-block;
  border-radius: 50%;
  color: white;
}
/* date picker css */
.DateContainer {
  position: relative;
  width: 340px;
}

.dateInput {
  width: 100%;
  height: inherit;
  display: block;
}

.dateInput input {
  width: 100%;
  background: transparent;
  color: #888888;
  font-size: 16px;
  height: 50px;
  display: block;
  border: 1px solid #888888;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  padding-right: 35px;
}

.icon {
  width: 30px;
  position: absolute;
  display: block;
  top: 9px;
  right: 6px;
}

.icon svg {
  margin-top: 3px;
}

textarea:focus,
input:focus {
  outline: none;
}
.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

/* Color picker */
.ColorPick {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  position: relative;
}
.clickableDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  cursor: pointer;
}
.color-picker {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  right: 10px;
}

.color-input {
  width: 100%;
  height: 100%;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: 0 !important;
  opacity: 0;
}
